<template>
  <div>
    <template v-if="!empty(selValue)">
      <object :data="no_img" type="image/png" width="50">
        <img
          :width="empty(height) ? width + 'px' : ''"
          :height="height + 'px'"
          class="el-upload-list__item-thumbnail"
          v-if="!empty(selValue)"
          @click="
            imageClick == true ? handlePictureCardPreview(selValue) : null
          "
          :src="imUrl + selValue"
          :style="{ cursor: imageClick == true ? 'pointer' : '' }"
          alt=""
        />
      </object>
      <div class="mt-3" v-if="showZoom">
        <span
          @click="handlePictureCardPreview(selValue)"
          class="mr-2"
          style="cursor: pointer"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span v-if="!disabled" style="cursor: pointer" @click="removeFile">
          <i class="el-icon-delete"></i>
        </span>
      </div>
    </template>
    <template v-else>
      <img
        width="50px"
        class="el-upload-list__item-thumbnail"
        :src="no_img"
        alt=""
      />
    </template>
    <v-dialog
      v-model="dialogVisible"
      width="600px"
      transition="dialog-bottom-transition"
    >
      <img width="100%" :src="imUrl + dialogImageUrl" alt="" />
    </v-dialog>
  </div>
</template>
<script>
export default {
  created() {
    this.selValue = this.value;
  },
  watch: {
    value: function () {
      this.selValue = this.value;
    },
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    folder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showZoom: {
      type: Boolean,
      default: true,
    },
    imageClick: {
      type: Boolean,
      default: true,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: null,
    },
    width: {
      type: Number,
      default: 150,
    },
  },
  newd() {},
  components: {},
  data() {
    return {
      selValue: "",
    };
  },
  methods: {
    removeFile() {
      this.selValue = null;
      this.$emit("input", null);
    },
  },
  name: "ImageField",
};
</script>
