<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      width="700px"
      hide-overlay
      fullscreen
      persistent
      transition="dialog-bottom-transition"
    >
      <v-container class="pa-0">
        <v-card class="pb-10">
          <v-system-bar height="47" color="#aeddff">
            <h3 class="black--text">
              {{ title }}
            </h3>
            <v-spacer />
            <v-text-field
              dense
              solo
              height="20px"
              placeholder="Type and hit Enter"
              class="mt-6 mr-2"
              color="white"
              prepend-inner-icon="mdi-magnify"
              @keyup.enter.native="fitlerData"
              v-model="search"
            ></v-text-field>
            <v-spacer />
            <v-btn
              title
              color="error"
              class="ml-2"
              @click="close"
              elevation="0"
            >
              <v-icon color="white">mdi-close-circle</v-icon>
              Close
            </v-btn>
          </v-system-bar>
          <v-divider />
          <v-card-text>
            <div v-for="item in voters" class="pa-0 mb-1" :key="item.id">
              <div class="row mb-4" style="border-bottom: 1px solid grey">
                <div class="col-xs-3 col-sm-2 col-md-1 col-lg-1 col-xl-1">
                  <image-field
                    class="mt-2 ml-2"
                    size="mini"
                    :disabled="true"
                    :show-zoom="false"
                    :image-click="true"
                    :grid="true"
                    :width="0"
                    :height="50"
                    :value="item.photo"
                  />
                </div>
                <div class="col-xs-9 col-sm-10 col-md-11 col-lg-11 col-xl-11">
                  <div class="row">
                    <div class="col-12 pb-0 title">
                      {{ item.full_name }}
                    </div>
                    <div
                      class="col-12 pt-0 pb-0"
                      style="max-height: 45px; overflow: hidden"
                    >
                      {{ item.address }}
                    </div>
                    <div
                      class="col-12 pt-0 pb-0"
                      style="max-height: 35px; overflow: hidden"
                    >
                      <span class="mdi mdi-phone-outline"></span>
                      <a :href="'tel:' + item.mobile_number">
                        {{ item.mobile_number }}
                      </a>
                    </div>
                    <div
                      class="col-12 pt-0 pb-0"
                      style="max-height: 35px; overflow: hidden"
                    >
                      <span class="mdi mdi-mail-outline"></span>
                      <a :href="'mailto:' + item.email">{{ item.email }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <el-pagination
              @size-change="sizeChanged"
              @current-change="getData"
              :current-page.sync="currentPage"
              :page-sizes="[50, 100, 200, 300, 400]"
              :page-size="perPage"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              class="mb-3"
            ></el-pagination>
          </v-card-text>
          <v-overlay :value="loading" :opacity="0.7">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-card>
      </v-container>
    </v-dialog>
  </v-row>
</template>
<script>
import ImageField from "@/components/custom/ImageField.vue";
import Vue from "vue";
import { Button, Pagination } from "element-ui";
Vue.use(Button);
Vue.use(Pagination);
export default {
  created() {},
  watch: {
    show: function () {
      this.showForm = this.show;
      if (this.showForm) {
        this.search = "";
        this.getData();
        this.title - this.list.title;
      }
    },
  },
  props: {
    campaign: null,
    list: null,
    show: {
      type: Boolean,
      default: false,
    },
  },
  newd() {},
  components: {
    ImageField,
  },
  data() {
    return {
      title: "Voters",
      loading: false,
      showForm: false,
      voters: [],
      search: "",
      currentPage: 1,
      perPage: 50,
      total: 0,
      sort: "populations.id_card_no|asc",
    };
  },
  methods: {
    sizeChanged(page) {
      this.perPage = page;
      this.getData();
    },
    fitlerData() {
      this.currentPage = 1;
      this.getData();
    },
    sortChange(col) {
      this.sort = col.prop + "|" + col.order;
      this.getData();
    },
    getData() {
      let f = {
        campaigns_id: this.campaign.id,
        filter: this.search,
        page: this.currentPage,
        per_page: this.perPage,
        sort: this.sort,
      };
      this.loading = true;
      this.api("public/" + this.list.action, f)
        .then((response) => {
          this.loading = false;
          this.voters = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("close");
    },
  },
  computed: {},
  name: "CampaignViewVoters",
};
</script>
